import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { ProgressBar } from ".";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "progressbar"
    }}>{`ProgressBar`}</h1>
    <p>{`ProgressBar allows you to visualise progress in a longer form or process.
(Other forms of progress indicators TBD)`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={ProgressBar} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`Note the two Components in the Playground below:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`alignTo='page'`}</inlineCode>{` (default) => check on top of this page`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`alignTo='component'`}</inlineCode>{` => check on top of this Playground frame.`}</li>
    </ul>
    <Playground __position={1} __code={'<ProgressBar percent={50} />\n<ProgressBar alignTo=\"component\" percent={50} />'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      ProgressBar,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ProgressBar percent={50} mdxType="ProgressBar" />
  <ProgressBar alignTo="component" percent={50} mdxType="ProgressBar" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      